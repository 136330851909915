$( document ).ready(function() {

  $(".cat-down").click(function(e){
    // e.preventDefault();
    $('#myModal').modal('show');
  });

    $(".column .logo").matchHeight();

    $(window).resize( function() {
      var offset = $(".top-header").height() + $(".medium-header").height();
      $(".bottom-header").followWhen(offset);
    });

    var offset = $(".top-header").height() + $(".medium-header").height();
    $(".bottom-header").followWhen(offset);

  // enquire.register("screen and (max-width:1024px)", 
  // {
  //     match : function()
  //     {
        // $(".bottom-header").followWhen(0);
        // $(".Content-Jordana").css("padding-top", $(".bottom-header").height() + "px");
        // $(".Content-Milani").css("padding-top", $(".bottom-header").height() + "px");
  //     },
  //     setup : function() {}, 
  //     destroy : function() {},
  //     deferSetup : true
  // });


  // enquire.register("screen and (min-width:1025px)", 
  // {
  //     match : function()
  //     {
  //       $(".bottom-header").followWhen(86);
  //     }, 
  //     setup : function() {}, 
  //     destroy : function() {},
  //     deferSetup : true
  // });

    $('input').iCheck({
      checkboxClass: 'icheckbox_minimal',
      radioClass: 'iradio_minimal',
      increaseArea: '20%' // optional
    });

    $("#payment-method").change( function() {
      $(".PaymentForm").addClass("hidden");
      if($(this).val().length > 0) {
        $("#PaymentForm_"+$(this).val()).removeClass("hidden");

        if(($(this).val() * 1) == 5 || ($(this).val() * 1) == 1) {
          $("#Totals").hide();
        } else {
          $("#Totals").show();
        }
      }
    });


	$(".wrap_desktop").camera({
      loader: "none",
      navigation: true,
      fx: 'scrollHorz',
      pagination: false,
      height:'30%',
      alignment: 'center',
      autoAdvance: true,
      hover: false,
      playPause: false,
      time: 500,
      alignment: 'topCenter',
      // pauseOnClick: false
   });  

   $(".wrap_mobile").camera({
      loader: "none",
      navigation: true,
      fx: 'scrollHorz',
      pagination: false,
      height:'420',
      alignment: 'center',
      autoAdvance: true,
      hover: false,
      playPause: false,
      time: 500,
      alignment: 'topCenter',
      // pauseOnClick: false
   }); 

       var text = ['<i class="fa fa-angle-left"></i>','<i class="fa fa-angle-right"></i>'];
   
    $(".three-slides .owl-carousel").owlCarousel({
      center: true,
      margin: 40,
      loop: true,
      lazyLoad: true,
      nav: true,
      navText: text,
      dots: false,
      responsiveClass:true,
      responsive:{
          0:{
              items:1,
              nav:true
          },
          300:{
              items:1,
              nav:false
          },
          1000:{
              items:3,
              nav:true,
              loop:true
          },
          1920:{
              items:3,
              nav:true,
              loop:true
          },
      }
    });
    $(".four-slides .owl-carousel").owlCarousel({
      center: true,
      margin: 20,
      loop: true,
      lazyLoad: true,
      nav: true,
      navText: text,
      dots: false,
      responsiveClass:true,
      responsive:{
          0:{
              items:2,
              nav:true
          },
          600:{
              items:3,
              nav:false
          },
          1000:{
              items:3,
              nav:true,
              loop:true
          },
          1920:{
              items:4,
              nav:true,
              loop:true
          },
      }
    });

  if($(".sidebars").length != 0)
  {
    // All sides
    var sides = ["left", "top", "right", "bottom"];
    //$("h1 span.version").text($.fn.sidebar.version);
  
    // Initialize sidebars
    for (var i = 0; i < sides.length; ++i) {
        var cSide = sides[i];
        $(".sidebar." + cSide).sidebar({side: cSide});
    }

    // Click handlers
    $(".btn[data-action]").on("click", function () {
        var $this = $(this);
        var action = $this.attr("data-action");
        var side = $this.attr("data-side");
        $(".sidebar." + side).css("display", "block");
        $(".sidebar." + side).trigger("sidebar:" + action);
        return false;
    });


  $(".img-detail-link").click( function(e) {
    e.preventDefault();
    var src = $(this).attr("href");

    $(".zoomContainer").remove();
    // $('#zoomed img').removeData('elevateZoom');
    // $('.zoomWrapper img.zoomed').unwrap();
    $("#img-detail").removeData('elevateZoom');
    $("#img-detail").removeData('zoomImage');

    // $("#img-detail").removeAttr("zoom-image");

    $("#img-detail").attr("src", src);
    $("#img-detail").attr("data-zoom-image", src);

    // enquire.register("screen and (min-width:1024px)", {
    //     match : function() {
        $("#img-detail").elevateZoom({
          zoomType : "lens",
          lensShape : "round",
          lensSize : 250
        });
    //   },
    //   unmatch : function() {},
    //   setup : function() {},
    //   deferSetup : true,
    //   destroy : function() {}
    // });
  });

  $.each($(".img-container"), function() {
    $(this).css('background-image', "url(" + $(this).data("src") + ")");
  });

  $(".phone-field").mask("00 0000 0000");

}
});