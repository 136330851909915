var windw = this;

$.fn.followWhen = function ( pos ) {
    var $this = this,
        $window = $(windw);

    $(window).unbind("scroll");
    $window.scroll(function(e){

        if ($window.scrollTop() >= pos && pos > 0) {
            $this.css({
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                'z-index': 9999
            });
            $(".Content-Jordana").css("margin-top", $(".bottom-header").height() + "px");
            $(".Content-Milani").css("margin-top", $(".bottom-header").height() + "px");
            $(".slideup").fadeIn();
        } else {
            $this.css({
                position: 'relative',
                top: 0
            });
            $(".Content-Jordana").css("margin-top", "0");
            $(".Content-Milani").css("margin-top", "0");
            $(".slideup").fadeOut();

        }
    });
};