$( document ).ready(function() {
     $.each($(".select-color"), function() {
        if(!$(this).hasClass("img-container")) {
          $(this).css('background-color', $(this).data("color"));
        }
      });


    $(".fake-checkbox").click( function() {
      var group = $(this).data("group");
      if($(this).hasClass("exclude")) {
         $(".fake-checkbox."+group).not($(this)).removeClass("checked");
      }
      $(this).toggleClass("checked");  
   });

   $.each($(".img-container"), function() {
		$(this).css('background-image', "url(" + $(this).data("src") + ")");
	});

  enquire.register("screen and (min-width:1024px)", {
      match : function() {
  		$(".img-zoomable").elevateZoom({
  		   zoomType				: "lens",
  		  lensShape : "round",
  		  lensSize    : 250
  		  });
  	},
    unmatch : function() {},
    setup : function() {},
    deferSetup : true,
    destroy : function() {}
  });


});