$( document ).ready(function() {
	$( ".categorie-menu" ).click(function(e) {
		e.preventDefault();
		var submenu = $(this).data("submenu");
	  	$( submenu ).slideToggle( "slow" );
	});

	$( ".categorie-menu-mob" ).click(function(e) {
		e.preventDefault();
		var submenu = $(this).data("submenu");
	  	$( submenu ).slideToggle( "slow" );
	});

	$( ".exclude" ).click(function(e) {
		e.preventDefault();
	});

	$(".product").matchHeight();

});